import AnchorLink from '@app/components/common/links/anchor-link';
import { useRouter } from 'next/router';

export default function Footer() {
    const router = useRouter();
    const currentPage = router.query.page;
    return (
        <div className="flex h-[144px] w-full items-center justify-center px-6 sm:px-10">
            <div className="body3 flex w-full max-w-[1440px] flex-wrap items-center justify-between gap-2 text-text-contrast sm:gap-6">
                <p> &copy; 2024 Sireto Technology. All rights reserved.</p>
                <div className="flex items-center gap-2 sm:gap-6">
                    <AnchorLink target="_blank" href="https://docs.formurl.com/privacy-policy/">
                        Privacy Policy
                    </AnchorLink>
                    <AnchorLink target="_blank" href="https://docs.formurl.com/terms-of-service/">
                        Terms of Services
                    </AnchorLink>
                </div>
            </div>
        </div>
    );
}
