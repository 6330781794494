import React from 'react';
import cn from 'classnames';
import Navbar from '@app/layouts/navbar';
import Footer from '@app/layouts/footer';

interface ILayoutProps {
    className?: string;
}

export default function Layout({ children, className }: React.PropsWithChildren<ILayoutProps>) {
    return (
        <div className="dark:bg-dark z-20 !min-h-screen !min-w-full bg-white-1">
            <Navbar />
            <main className={cn(`flex h-full min-h-calc-212 w-full flex-col items-center justify-center`, className)}>{children}</main>
            <Footer />
        </div>
    );
}
