import NextNProgress from '@app/components/ui/nprogress';
import createEmotionCache from '@app/configs/createEmotionCache';
import Layout from '@app/layouts/_layout';
import '@app/assets/css/globals.css';
import 'react-toastify/dist/ReactToastify.css';
import { NextPageWithLayout } from '@app/types';
import { CacheProvider, EmotionCache } from '@emotion/react';
import type { AppProps } from 'next/app';
import { ToastContainer } from 'react-toastify';
import { ScrollProvider } from '@app/context/scroll-context';
import { Provider } from 'react-redux';
import { store } from '@app/store/store';
import ModalContainer from '@app/components/modal-views/container';
import { NextSeo } from 'next-seo';
import globalConstants from '@app/constants/global';
import { RenderFormProps } from './to/[mappedPath]';
import environments from '@app/configs/environments';
import { LinkDetails } from '@app/store/formUrl/types';
import { capitalize } from '@app/utils/stringUtils';

const clientSideEmotionCache = createEmotionCache();
type AppPropsWithLayout = AppProps & {
    Component: NextPageWithLayout;
    emotionCache?: EmotionCache;
    pageProps: RenderFormProps | any;
};
export default function App({ Component, pageProps, router, emotionCache = clientSideEmotionCache }: AppPropsWithLayout) {
    const getLayout = Component.getLayout ?? ((page: any) => page);

    // default
    let title = globalConstants.title || globalConstants.socialPreview.title;
    let description = globalConstants.socialPreview.desc;
    let url = globalConstants.socialPreview.url;
    let imageUrl = globalConstants.socialPreview.image;

    // customize form preview
    if (pageProps?.mappedPath) {
        title = capitalize(pageProps.mappedPath);
        imageUrl = '/social-preview-form.png';
        description = 'Fill out this Form by clicking on the custom link.';
        url = url + 'to/' + pageProps?.mappedPath;
    }

    return (
        <CacheProvider value={emotionCache}>
            <NextSeo
                title={title || globalConstants.socialPreview.title}
                description={description}
                openGraph={{
                    type: 'website',
                    locale: 'en_IE',
                    url,
                    site_name: title || globalConstants.appName,
                    description: description,
                    title,
                    images: [
                        {
                            url: imageUrl,
                            alt: title ?? 'formurl'
                        }
                    ]
                }}
                additionalMetaTags={router.pathname === '/' ? [globalConstants.keywords] : []}
                twitter={{
                    handle: globalConstants.twitterHandle,
                    site: url,
                    cardType: 'summary_large_image'
                }}
            />
            <NextNProgress color="#0764EB" startPosition={0} stopDelayMs={400} height={2} options={{ easing: 'ease' }} />
            <ToastContainer position="bottom-center" autoClose={3000} hideProgressBar newestOnTop closeOnClick rtl={false} pauseOnFocusLoss={false} draggable pauseOnHover={false} theme="light" />
            <Provider store={store}>
                {router.asPath.includes('/to/')
                    ? getLayout(<Component {...pageProps} key={router.asPath} />)
                    : getLayout(
                          <ScrollProvider>
                              <Layout>
                                  <Component {...pageProps} key={router.asPath} />
                              </Layout>
                          </ScrollProvider>
                      )}
                <ModalContainer />
            </Provider>
        </CacheProvider>
    );
}
