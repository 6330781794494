import getConfig from 'next/config';

const IS_IN_PRODUCTION_MODE = process.env.NEXT_PUBLIC_NODE_ENV === 'production';

const environments = {
    // build-time configs
    IS_IN_PRODUCTION_MODE,
    API_ENDPOINT_HOST: process.env.NEXT_PUBLIC_API_ENDPOINT_HOST || 'https://api.formurl.com',
    FEEDBACK_FORM: process.env.NEXT_PUBLIC_FEEDBACK_FORM
};

export default environments;
