import SuccessIcon from '@app/components/icons/success-icon';
import TextInput from '@app/components/common/input/TextInput';
import { useModal } from '../context';
import { toast } from 'react-toastify';
import { ToastId } from '@app/constants/toast-id';

export default function SuccessModal({ mappedUrl }: { mappedUrl: string }) {
    const { closeModal } = useModal();
    const handleCopyCustomizeLink = () => {
        navigator.clipboard.writeText(mappedUrl);
        toast.success('Copied to clipboard', { toastId: ToastId.SUCCESS_TOAST });
    };
    return (
        <div className="flex w-full max-w-[600px] flex-col items-center rounded-[8px] bg-white-1 p-6 sm:p-10">
            <SuccessIcon className="h-[60px] w-[60px] sm:h-[90px] sm:w-[90px]" />
            <p className="h5 mt-4 text-center text-text-primary">Your customized link has been successfully created!</p>
            <div className="my-4 flex w-full max-w-[450px] flex-col gap-3 sm:my-6">
                <p className="body2-responsive font-semibold text-text-secondary">Your customized link</p>
                <TextInput value={mappedUrl} readOnly type="text" className="h-14 overflow-ellipsis rounded-[12px] border border-border-primary shadow-md sm:h-[60px]" />
            </div>
            <div className="flex w-full max-w-[450px] gap-4 sm:gap-6">
                <button onClick={handleCopyCustomizeLink} className="body2-responsive w-full rounded-lg bg-secondary-500 px-6 py-3 font-semibold text-white-1">
                    Copy
                </button>
                <button onClick={closeModal} className="body2-responsive w-full rounded-lg border-2 border-neutral-200 px-6 py-3 font-semibold">
                    Close
                </button>
            </div>
        </div>
    );
}
