import React from 'react';

export default function SuccessIcon(props: React.SVGAttributes<{}>) {
    return (
        <svg width="90" height="90" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M82.4998 41.55V45C82.4952 53.0866 79.8766 60.955 75.0348 67.4318C70.1929 73.9086 63.3871 78.6468 55.6323 80.9396C47.8776 83.2324 39.5895 82.9571 32.004 80.1547C24.4186 77.3522 17.9422 72.1729 13.5409 65.389C9.1395 58.6051 7.04897 50.5802 7.58104 42.5112C8.11311 34.4421 11.2393 26.7612 16.4933 20.614C21.7473 14.4668 28.8477 10.1827 36.7355 8.40053C44.6232 6.61839 52.8758 7.43374 60.2623 10.725"
                stroke="#2DBB7F"
                strokeWidth="6.825"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M82.5 15L45 52.5375L33.75 41.2875" stroke="#2DBB7F" strokeWidth="6.825" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}
