import environments from '@app/configs/environments';

const globalConstants = {
    appName: 'FormURL',
    appDesc: 'Create custom links for your forms easily with our service. Personalize Forms and other popular platforms with branded URLs to improve user experience and boost engagement. Start today for a seamless form sharing solution!',
    title: 'FormURL - Customize your Form link',
    favIcon: 'favIcon',
    twitterHandle: '@formurl',
    titleImg: 'titleImg',
    socialPreview: {
        url: `https://formurl.com`,
        title: 'FormURL - Customize your Form link',
        desc: 'Create custom links for your forms easily with our service. Personalize Forms and other popular platforms with branded URLs to improve user experience and boost engagement. Start today for a seamless form sharing solution!',
        image: `/hero-section.png`
    },
    consoleWarningTitle: `%cStop!`,
    consoleWarningDescription: `%cThis is a browser feature intended for developers. If someone told you to copy-paste something here to enable a feature, it is a scam and will give them access to your sensitive information.`,
    imageTypes: ['image/jpg', 'image/jpeg', 'image/png', 'image/gif', 'image/svg+xml'],
    keywords: {
        name: 'keywords',
        content: 'custom links, form URL, Google Forms, branded links, custom form links, personalized form URLs, form sharing, online forms, form link customization.'
    }
};

export default globalConstants;
