import { Close } from '@app/components/icons/close';
import { useModal } from '../context';
import Image from 'next/image';
import CopyGoogleFormUrl from '@app/assets/image/copy-google-form-url.gif';

export default function CopyGoogleFormUrlDemo() {
    const { closeModal } = useModal();
    return (
        <div className="flex w-full max-w-[1000px] flex-col gap-6 rounded-[8px] bg-transparent p-10 lg:w-screen lg:max-w-[800px]">
            <Image src={CopyGoogleFormUrl} className="h-full w-full" alt="demo" />
            <div className="absolute right-1 top-1 cursor-pointer rounded-full bg-white-1 p-1 text-gray-600 hover:text-black" onClick={() => closeModal()}>
                <Close className="h-8 w-8 text-black-2 dark:text-white" />
            </div>
        </div>
    );
}
