import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import environments from '@app/configs/environments';
import { LinkDetails, LinkExistRequestBody, SubscribeRequestBody, SubscribeResponse } from './types';

export const FORM_URL_PATH = 'formUrl';
const LINK_TAGS = 'LINK_TAGS';

export const formUrlApi = createApi({
    reducerPath: FORM_URL_PATH,
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
    tagTypes: [LINK_TAGS],
    keepUnusedDataFor: 0,
    baseQuery: fetchBaseQuery({
        baseUrl: environments.API_ENDPOINT_HOST,
        prepareHeaders: (headers) => {
            headers.append('Content-Type', 'application/json');
            return headers;
        }
    }),
    endpoints: (builder) => ({
        getLinks: builder.query<Array<LinkDetails>, string>({
            query: (fingerPrint) => ({
                url: `/links?fingerprint=${fingerPrint}`,
                method: 'GET'
            }),
            providesTags: [LINK_TAGS]
        }),
        customizeFormLink: builder.mutation<LinkDetails, LinkDetails>({
            query: (linkBody) => ({
                url: `/links`,
                method: 'POST',
                body: JSON.stringify(linkBody)
            }),
            invalidatesTags: [LINK_TAGS]
        }),
        linkExist: builder.mutation<LinkDetails, LinkExistRequestBody>({
            query: (linkExistBody) => ({
                url: `/exists`,
                method: 'POST',
                body: linkExistBody
            })
        }),
        subscribe: builder.mutation<SubscribeResponse, SubscribeRequestBody>({
            query: ({ emailAddress }) => ({
                url: `/subscribe`,
                method: 'POST',
                body: { emailAddress }
            })
        })
    })
});

export const { useGetLinksQuery, useCustomizeFormLinkMutation, useLinkExistMutation, useSubscribeMutation } = formUrlApi;
