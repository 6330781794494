import { Fragment, useEffect } from 'react';

import { useRouter } from 'next/router';

import { MODAL_VIEW, useModal } from '@app/components/modal-views/context';
import { Dialog } from '@app/components/ui/dialog';
import { Transition } from '@app/components/ui/transition';
import SuccessModal from '@app/components/modal-views/modals/custom-upload-document-modal';
import CopyGoogleFormUrlDemo from '@app/components/modal-views/modals/copy-google-form-url-demo';

function renderModalContent(view: MODAL_VIEW | string, modalProps: any) {
    switch (view) {
        case 'SUCCESS_MODAL':
            return <SuccessModal {...modalProps} />;
        case 'COPY_GOOGLE_FORM_URL_DEMO':
            return <CopyGoogleFormUrlDemo />;
        default:
            return <></>;
    }
}

export default function ModalContainer() {
    const router = useRouter();
    const { view, isOpen, closeModal, modalProps } = useModal();

    const closeModalHandler = () => {
        closeModal();
    };

    useEffect(() => {
        // close search modal when route change
        router.events.on('routeChangeStart', closeModalHandler);
        return () => {
            router.events.off('routeChangeStart', closeModalHandler);
        };
    }, []);

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="3xl:p-12 fixed inset-0 z-[2500] h-full w-full overflow-y-auto overflow-x-hidden p-4 text-center sm:p-6 lg:p-8 xl:p-10" onClose={closeModalHandler}>
                <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                    <Dialog.Overlay
                        onClick={(event) => {
                            if (modalProps && !!modalProps['preventCloseOnOutsideClick']) {
                                event.stopPropagation();
                                event.preventDefault();
                            }
                        }}
                        className="fixed inset-0 z-40 cursor-pointer bg-gray-700 bg-opacity-60 backdrop-blur"
                    />
                </Transition.Child>

                {/* This element is to trick the browser into centering the modal contents. */}
                {view && view !== 'SEARCH_VIEW' && (
                    <span className="inline-block h-full align-middle" aria-hidden="true">
                        &#8203;
                    </span>
                )}

                <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-105" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-105">
                    <div data-testid="modal-view" className="relative z-50 inline-block w-full text-left align-middle md:w-fit">
                        {view && renderModalContent(view, modalProps)}
                    </div>
                </Transition.Child>
            </Dialog>
        </Transition>
    );
}
